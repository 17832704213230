<template>
  <div class="updateBox" v-loading="loading">
    <el-card class="box-card">
      <el-form
        ref="form"
        :model="form"
        label-width="85px"
        label-position="top"
        :rules="rules"
        hide-required-asterisk
      >
        <el-row>
          <el-col :span="13">
            <el-form-item label="公告标题：" prop="title" :error="errorMessage?errorMessage.title:''">
              <el-input v-model="form.title" placeholder="请输入公告标题"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label-width="0px" class="switch_item">
              <el-switch
                v-model="form.published"
                active-text="发布显示"
                inactive-text="显示关闭"
                :active-value="1"
                :inactive-value="0"
              ></el-switch>
              
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="11">
            <el-form-item label="排序位置：" prop="order" :error="errorMessage?errorMessage.order:''">
              <el-input v-model.number="form.order" placeholder="请填写排序位置"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="类型：" prop="type">
              <el-select v-model="form.type" placeholder="请选择类型">
                <el-option
                  v-for="item in typeOptions"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="11">
            <el-form-item label="是否置顶：" prop="top" :error="errorMessage?errorMessage.top:''">
              <el-select v-model="form.top" placeholder="请选择是否置顶">
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="推送范围：">
              <el-select v-model="form.visible_plate_form" multiple placeholder="请选择推送范围">
                <el-option
                  v-for="item in visibleOptions"
                  :key="item.key"
                  :label="item.title"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item prop="content">
          <tinymce v-model="form.content" :height="300" @callback="callback" />
        </el-form-item>
        <el-form-item class="btn_item">
          <el-button type="primary" @click="onSubmit">确认</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import api from "@/api/guildManageapi";
import tinymce from "@/components/Tinymce";
const typeOptions = [
  { key: 0, value: "全部" },
  { key: 1, value: "咨询" },
  { key: 2, value: "小程序公告" },
  { key: 3, value: "紧急通知" },
];
const options = [
  { id: 0, value: "否" },
  { id: 1, value: "是" },
];
export default {
  components: { tinymce },
  data() {
    return {
      loading:true,
      errorMessage:'',
      form: {
        announce_id: null,
        title: "",
        published: 0,
        order: 1,
        type: 0,
        top: 0,
        visible_plate_form: [],
      },
      typeOptions,
      options,
      visibleOptions: [],
      rules: {
        title: [{ required: true, message: "请输入公告标题", trigger: "blur" }],
        order: [
          { required: true, message: "请填写排序位置", trigger: "blur" },
          { type: "number", message: "排序位置必须为数字值" },
        ],
        type: [{ required: true, message: "请选择类型", trigger: "change" }],
        // visible_plate_form: [
        //   {
        //     type: "array",
        //     required: true,
        //     message: "请至少选择一个推送范围",
        //     trigger: "change",
        //   },
        // ],
        top: [{ required: true, message: "请选择是否置顶", trigger: "change" }],
        content: [{ required: true, message: "请填写内容", trigger: "blur" }],
      },
    };
  },
  props: {
    noticeForm: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    noticeForm: {
      handler: function (val) {
        if (val != undefined) {
          this.form = val;
          if (this.form.visible_plate_form != undefined)
            this.form.visible_plate_form = this.form.visible_plate_form.map(
              Number
            );
          // this.form.published
        }else{
          this.loading = false
        }
      },
      immediate: true,
    },
  },
  created() {
    this._getCreate();
  },
  methods: {
    callback(res) {
      console.log("asdasd", res);
    },
    //获取推送范围
    async _getCreate() {
      const data = await api.getCreate();
      if (data.status_code === 200) {
        this.visibleOptions = data.message;
        this.loading = false
      }
    },
    //提交表单
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.noticeForm) {
            this.submitFnc(
              "noticeEdit",
              this.form,
              "/backstage/noticepage",
              "修改"
            );
          } else {
            this.submitFnc(
              "noticeAdd",
              this.form,
              "/backstage/noticepage",
              "添加"
            );
          }
        } else {
          return false;
        }
      });
    },
    async submitFnc(apis, formObj, path, diaMessage) {
      const { status_code, message } = await api[apis](formObj);
      if (status_code === 200) {
        this.$router.push({ path: path });
        this.$message({
          message: diaMessage + "成功",
          type: "success",
        });
      } else if (status_code === 422) {
        this.errorMessage = message;
      } else {
        this.$message({
          message: diaMessage + "失败",
          type: "warning",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
// @import "./headCss.less";
.updateBox /deep/ .ck-editor__editable {
  min-height: 100px;
}
.updateBox /deep/.el-col {
  width: 480px;
}
.updateBox /deep/ .btn_item {
  margin-bottom: 0px;
}
.updateBox /deep/ .el-input__inner {
  height: 34px;
  line-height: 34px;
  width: 400px;
  color: #333333;
}
.updateBox /deep/ .el-form-item {
  margin-bottom: 40px;
}
.updateBox /deep/ .switch_item.el-form-item {
  margin-bottom: 0px;
  height: 80px;
  display: flex;
  align-items: center;
}
.updateBox /deep/ .el-form-item__content {
  line-height: 34px;
}
.updateBox /deep/ .el-form-item__label {
  line-height: 1;
  height: 26px;
}
.updateBox /deep/ .el-form--label-top .el-form-item__label {
  padding: 0 0 12px;
}
.updateBox /deep/ .tinymce-container {
  width: 880px !important;
}
.account {
  height: 58px;
  line-height: 58px;
}
.screenBtn {
  height: 58px;
  line-height: 58px;
  margin-bottom: 10px;
}
.cus_button {
  color: #666666;
}
.updateBox /deep/ .el-switch.is-checked .el-switch__core {
  border-color: rgba(255, 255, 255, 0);
  background: linear-gradient(270deg, #fe61a2 0%, #f39064 100%);
}
.updateBox /deep/ .el-switch__label * {
  line-height: 20px;
}
.updateBox /deep/ .el-switch__label {
  height: 18px;
}
.updateBox /deep/.el-input__icon{
  line-height: 34px;
}
</style>